// Dependency
import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby";

// Internationalization
//import i18n from "../../internationalization/i18n";
import { useTranslation } from 'react-i18next';

// Components
import Layout from '../../components/layouts';
import SectionTitle from '../../components/section-title/section-title';
import BlogPostGrid from "../../components/blog-post/blog-post-grid/blog-post-grid";
import Tags from "../../components/tags/tags";
import BlogPostList from "../../components/blog-post/blog-post-list/blog-post-list";
import Subscribe from "../../components/subscribe/subscribe";
import Hero from "../../components/hero/hero";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Assets
import HeaderCats from "../../components/header/cats";

// Dummy data
// const headerCats = [
//   {
//     name: 'Featured',
//     link: '/category',
//   },
//   {
//     name: 'Träning',
//     link: '/category',
//   },
//   {
//     name: 'Återhämtning',
//     link: '/category',
//   },
//   {
//     name: 'Stretching',
//     link: '/category',
//   },
//   {
//     name: 'Flow',
//     link: '/category',
//   },
// ];

// Template
const BlogPage = (props) => {
  const pageData = props.data.datoCmsBlogPage;
  // Extract latest blog articles
  const latestBlogPostItems = props.data.latestPosts.nodes;
  // Extract featured blog articles
  const featuredBlogPostItems = props.data.featuredPosts.nodes;
  // Extract top story blog articles
  const topStoryBlogPostItems = props.data.topStory.nodes;
  //  Extract instagram feed data
  //const instagramFeedItems = props.data.allInstaNode.nodes;
  // All categories
  //const categories = props.data.allCategories.nodes;

  const mediaData = {
    videoSrcURL: pageData && pageData.desktopVideo ? pageData.desktopVideo.url : '',
    videoSrcURLMobile: pageData && pageData.mobileVideo ? pageData.mobileVideo.url : '',
  }
  const sources = [
    pageData.mobileHero.fluid ,
    {
      ...pageData.desktopHero.fluid ,
      media: `(min-width: 900px)`,
    },
  ]

  // Translation Function
  const { t } = useTranslation();

  // State for the list
  const [list, setList] = useState([...latestBlogPostItems.slice(0, 9)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(latestBlogPostItems.length > 9)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < latestBlogPostItems.length
      const nextResults = isMore
          ? latestBlogPostItems.slice(currentLength, currentLength + 9)
          : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < latestBlogPostItems.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  // Template
  return (
    <Layout  seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
      <HeaderCats data={pageData.categories} />

    {/* <Hero
        cname={"cd-hero-small-text"}
        mediadata={mediaData}
        sources={sources}
      >
      <h1>{pageData.title}</h1>
      </Hero> */}
      <div className="cd-max-width " id="cd-hero">
        <div className="layout--sidebar cd-page-section">
          <div className="content">
            <section className="cd-blog-hide-mobile">
              <SectionTitle title={`${t('blog.featured')}`} color={"pink"} />
              <BlogPostGrid
                columns={"two"}
                data={featuredBlogPostItems}
                hasExcerpt={true}
                limit={6}
                cname={"cd-top-big"}
              />
            </section>
            <section>
              <SectionTitle title={`${t('blog.latest')}`} color={"blue"} />
              <BlogPostGrid
                columns={"three"}
                data={list}
              />
              {hasMore ? (
                  <button className={`button button--outlined button--light cd-load-more-button`} onClick={handleLoadMore}>{t('blog.loadMore')}</button>
              ) : (
                  <p>{t('blog.noResults')}</p>
              )}
            </section>
          </div>
          <div className="sidebar cd-blog-hide-mobile">
            <section className="sidebar-content">
              <SectionTitle title={`${t('blog.topStories')}`} color={"pink"} />
              <BlogPostList data={topStoryBlogPostItems} />
              <div className="cd-blog-sticky">
                <div>
                  <h4>{t('widgets.topics')}</h4>
                  <Tags categories={pageData.categories} />
                </div>
                <div>
                  <h4>{t('widgets.subscribe.title')}</h4>
                  <div>
                    <Subscribe
                        size={"small"}
                        description={`${t('widgets.subscribe.description')}`}
                        label={`${t('widgets.subscribe.label')}`}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;

// GraphQL to get Dato data
export const query = graphql`
  query ( $locale: String! = "en" ){
    latestPosts: allDatoCmsArticle(
      filter: {locale: {eq: $locale}, title: {ne: null}}
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        slug
        seoSlug
        readingTime
        id
        featuredMedia {
           fluid{
              ...GatsbyDatoCmsFluid
            }
          title
        }
        categories {
          name
          slug
          id
        }
        author {
          id
          name
          featuredImage {
            url
          }
        }
      }
    }
     datoCmsBlogPage (locale: {eq: $locale}){
        locale
        title
        desktopHero{
          fluid(maxWidth: 1500){
              ...GatsbyDatoCmsFluid
            }
        }
        mobileHero{
          fluid{
              ...GatsbyDatoCmsFluid
            }
        }
        categories{
          id
          name
          slug
          locale
        }
        seoMetaTags {
          tags
          id
        }
     }
    featuredPosts: allDatoCmsArticle(
      filter: {locale: {eq: $locale}, featured: {eq: true}, title: {ne: null}}
      limit: 6
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        featured
        slug
        seoSlug
        date
        readingTime
        id
        excerpt
        featuredMedia {
           fluid{
              ...GatsbyDatoCmsFluid
            }
          title
        }
        categories {
          name
          slug
          id
        }
        author {
          id
          name
          featuredImage {
             fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    topStory: allDatoCmsArticle(
      filter: {locale: {eq: $locale}, topStory: {eq: true}, title: {ne: null}}
      limit: 8
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        topStory
        slug
        seoSlug
        date
        readingTime
        id
        featuredMedia {
           fluid{
              ...GatsbyDatoCmsFluid
            }
          title
        }
        categories {
          name
          slug
          id
        }
        author {
          id
          name
           featuredImage {
              fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    allCategories: allDatoCmsCategory(
      filter: {locale: {eq: $locale}}
    ) {
      nodes {
        id
        name
        slug
        locale
      }
    }
  }
`;